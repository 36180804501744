@import "../../global.scss";



.certificates {
  background-color: crimson;
  display: flex;
  position: relative;
  align-items: center;
  justify-items: center;
  overflow: hidden;
  
  h1{
    justify-self: center;
    align-self: flex-start;
    margin-left: 40%;
    font-size: 50px;
    color: white;
    @include mobile{
      display: none;
  }

  }
  .arrow {
    height: 50px;
    position: absolute;
    cursor: pointer;
    @include mobile{
      display: none;
  }

  

    &.left {
      left: 100px;
      transform: rotateY(180deg);
    }

    &.right {
      right: 100px;
    }
  }

  .slider {
    height: 350px;
    display: flex;
    position: absolute;
    left: 0;
    transition: all 1s ease-out;
    @include mobile{
      height: 100vh;
      flex-direction: column;
      justify-content: center;
  }


    .container {
      width: 100vw;
      display: flex;
      align-items: center;
      justify-content: center;
      

      .item {
        width: 700px;
        height: 100%;
        background-color: white;
        border-radius: 20px;
        display: flex;
        align-items: center;
        justify-content: center;

        @include mobile{
          width: 90%;
          height: 160px;
          margin: 20px 0 0 0;
          flex-direction: column;

      }
  

        .left {
          flex: 4;
          height: 80%;
          display: flex;
          align-items: center;
          justify-content: center;

          .leftContainer {
            width: 90%;
            height: 70%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            .imgContainer {
              width: 40px;
              height: 40px;
              border-radius: 50%;
              background-color: rgb(245, 179, 155);
              display: flex;
              align-items: center;
              justify-content: center;

            
              img {
                width: 25px;

              
              }
            }
            h2 {
              font-size: 20px;

            
            }
            p {
              font-size: 10px;
           
            }
            a {
              font-size: 10px;
              font-weight: 300;
              text-decoration: underline;
              cursor: pointer;
            }
          }
        }
        .right {
          flex: 8;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          overflow: hidden;
          @include mobile{
            display: none;
        }
    
          img {
            width: 400px;
            transform: rotate(-10deg);
          }
        }
      }
    }
  }
}
