@import "../../global.scss";

.navbar {
  width: 100%;
  height: 70px;
  background-color: white;
  color: $mainColor;
  position: fixed;
  overflow: hidden;
  top: 0;
  z-index: 3;
  transition: all 2s ease;
  &.active{
    background-color: $mainColor;
    color: white;
  
    .hamburger{
        span{
            &:first-child{
                background-color: white;
                transform: rotate(45deg);
            }
            &:nth-child(2){
                opacity: 0;
            }
            &:last-child{
                background-color: white;
                transform: rotate(-45deg);
            }
        }
    }
}
}
.wrapper {
  padding: 10px 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .left {
    display: flex;
    align-items: center;
    .logo {
      font-size: 40px;
      font-weight: 700;
      text-decoration: none;
      color: inherit;
      margin-right: 40px;
    }
    .itemContainer {
      display: flex;
      align-items: center;
      margin-left: 30px;
      @include mobile{
        display: none;
    }

      .icon {
        font-size: 18px;
        font-weight: 500;
      }
      span {
        font-size: 15px;
        font-weight: 400;
      }
    }
  }
  .right {
      .hamburger{
        width: 32px;
        height: 25px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        cursor: pointer;

        span{
            width: 100%;
            height: 3px;
            background-color: $mainColor;
            transform-origin: left;
            transition: all 2s ease;
        
        }

      }  
    }

    
}
